import React from 'react'
import Slider from 'react-slick'; // Import React Slick
import "slick-carousel/slick/slick.css"; // Slick Carousel styles
import "slick-carousel/slick/slick-theme.css"; // Slick Carousel theme
import './Home.css'

const Home = () => {
  const settings = {
    dots: true,  // Show dots for navigation
    infinite: true,  // Infinite loop
    speed: 500,  // Transition speed
    slidesToShow: 2,  // Show 2 testimonials per row
    slidesToScroll: 1,  // Number of slides to scroll at once
    autoplay: true,  // Enable autoplay
    autoplaySpeed: 3000,  // Set autoplay speed
    responsive: [
      {
        breakpoint: 768,  // For tablet and mobile
        settings: {
          slidesToShow: 1,  // Show 1 testimonial on smaller screens
          dots: false,  // Hide dots on smaller screens
        },
      },
    ],
  };

  const handleSubmit = () => {

    // Get form values
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const subject = document.getElementById("subject").value.trim();
    const message = document.getElementById("message").value.trim();

    // Validate form fields
    if (!name || !email || !subject || !message) {
        alert("Please fill all the fields!");
        return;
    }

    // Create the WhatsApp message
    const whatsappMessage = `\n*Name:* ${name}\n*Email:* ${email}\n*Subject:* ${subject}\n*Message:* ${message}`;

    // WhatsApp API URL
    const phoneNumber = "+918788587234"; // WhatsApp number in international format
    const encodedMessage = encodeURIComponent(whatsappMessage);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Open WhatsApp in a new tab
    window.open(whatsappUrl, "_blank");
  }

  

  return (
    <div>
    <div className="main-banner header-text" id="top" >
  <div className="Modern-Slider">
    {/* Slide 1 */}
    <div className="item item-1">
      <div className="img-fill">
        <div className="text-content">
          <h6>Your Trusted Financial Partner</h6>
          <h4>Comprehensive Financial Assessments</h4>
          <p>
            At Cozfin Financial Asset Services, we specialize in providing personalized financial solutions to meet your unique needs.
            Our services include Tax Assessment, Retirement Goal Assessment, and Custom Goal Assessment to help you plan your future with confidence.
          </p>
          <a href="/contact" className="filled-button">Contact Us</a>
        </div>
      </div>
    </div>
    <div className="item item-2">
      <div className="img-fill">
        <div className="text-content">
          <h6>Plan for a Secure Tomorrow</h6>
          <h4>Child’s Future & Legacy Building</h4>
          <p>
            Ensure a bright financial future for your child with our Child Financial Future Assessment.
            Build a lasting legacy with our Legacy Building Assessment, designed to secure your wealth for generations to come.
          </p>
          <a href="/services" className="filled-button">Our Services</a>
        </div>
      </div>
    </div>

    {/* Slide 3 */}
    <div className="item item-3">
      <div className="img-fill">
        <div className="text-content">
          <h6>Secure Your Investments</h6>
          <h4>Financial Products & Insurance</h4>
          <p>
            Explore a range of financial products including Mutual Funds, NPS, Bonds, Fixed Deposits, and Loans against Mutual Funds.
            Safeguard your future with Life and General Insurance plans tailored to your needs.
          </p>
          <a href="/services" className="filled-button">Learn More</a>
        </div>
      </div>
    </div>
  </div>
</div>

 

    <div className="request-form">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h4>Request a call back right now ?</h4>
            <span>Have questions or need assistance? Request a callback, and our team will get in touch with you shortly to provide personalized support</span>
          </div>
          <div className="col-md-4">
            <a href="/contact" className="border-button">Contact Us</a>
          </div>
        </div>
      </div>
    </div>

    <div className="services">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="section-heading">
          <h2>Financial <em>Services</em></h2>
          <span>Your trusted partner for personalized financial solutions</span>
        </div>
      </div>

      <div className="col-md-4 mb-4"> {/* Added Bootstrap spacing utility class */}
        <div className="service-item" >
          <img src="assets/images/service_01_11zon.jpg" alt="Tax Assessment" />
          <div className="down-content">
            <h4>Tax Assessment</h4>
            <p>We provide expert guidance to help you navigate tax regulations and ensure accurate and efficient tax filings.</p>
            <a href="/contact" className="filled-button">Read More</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_02_11zon.jpg" alt="Retirement Goal Assessment" />
          <div className="down-content">
            <h4>Retirement Goal Assessment</h4>
            <p>Plan for a comfortable retirement with our customized retirement goal assessment services.</p>
            <a href="/services" className="filled-button">Read More</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_03_11zon.jpg" alt="Custom Goal Assessment" style={{objectFit:'cover'}}/>
          <div className="down-content">
            <h4>Custom Goal Assessment</h4>
            <p>Achieve your unique financial objectives with our personalized custom goal assessment services.</p>
            <a href="/services" className="filled-button">Read More</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_04_11zon.jpg" alt="Child Financial Future Assessment" />
          <div className="down-content">
            <h4>Child Financial Future Assessment</h4>
            <p>Secure your child’s financial future with strategic planning and expert advice.</p>
            <a href="/services" className="filled-button">Read More</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item">
          <img src="assets/images/service_05_11zon.jpg" alt="Adequate Insurance Assessment" />
          <div className="down-content">
            <h4>Adequate Insurance Assessment</h4>
            <p>Ensure comprehensive coverage with our insurance assessment tailored to your needs.</p>
            <a href="/services" className="filled-button">Read More</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_06_11zon.jpg" alt="Portfolio Review and Assessment" />
          <div className="down-content">
            <h4>Portfolio Review & Assessment</h4>
            <p>Maximize returns and minimize risks with our professional portfolio review services.</p>
            <a href="/services" className="filled-button">Read More</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_07_11zon.jpg" alt="Net Worth Review and Assessment"/>
          <div className="down-content">
            <h4>Net Worth Review & Assessment</h4>
            <p>Understand your financial health with our comprehensive net worth review services.</p>
            <a href="/services" className="filled-button">Read More</a>
          </div>
        </div>
      </div>

      <div className="col-md-4 mb-4">
        <div className="service-item" >
          <img src="assets/images/service_08_11zon.jpg" alt="Legacy Building Assessment" />
          <div className="down-content">
            <h4>Legacy Building Assessment</h4>
            <p>Preserve your wealth for future generations with our legacy building assessment services.</p>
            <a href="/services" className="filled-button">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    <div className="fun-facts">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
          <div className="left-content">
  <span>Trusted Financial Solutions</span>
  <h2>Empowering Your <em>Financial Growth</em></h2>
  <p>
    At Cozfin Financial Services, We Offer Tailored Solutions To Help You Achieve 
    Your Financial Goals. Whether It’s Investment Planning, Wealth Management, 
    Or Securing Loans, Our Expertise Ensures You Stay Ahead. We Prioritize Your 
    Success With Strategic Insights And Reliable Support.
  </p>
  <p>
    With A Client-First Approach And Innovative Strategies, We Make Navigating 
    Complex Financial Landscapes Seamless And Rewarding. Let Us Guide You 
    Towards A Secure And Prosperous Future.
  </p>
  <a href="/about" className="filled-button">Get Started</a>
</div>


          </div>
          <div className="col-md-6 align-self-center" >
            <div className="row" >
              <div className="col-md-6">
                <div className="count-area-content">
                <div className='inline'>
                     <div className="count-digit">10</div>
                     <p className='count-sign'>+</p>
                </div>
                  <div className="count-title">Cities Clientbase</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="count-area-content">
                <div className='inline'>
                     <div className="count-digit">40</div>
                     <p className='count-sign'>+</p>
                </div>
                  <div className="count-title">Happy Clients</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="count-area-content">
                <div className='inline'>
                     <div className="count-digit">4</div>
                     <p className='count-sign'>M+</p>
                </div>
                  <div className="count-title">AUM Managing</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="count-area-content">
                <div className='inline'>
                     <div className="count-digit">171</div>
                     <p className='count-sign' >K+</p>
                </div>
                  <div className="count-title">Live SIP Book</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="more-info">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="more-info-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="left-image">
                    <img src="assets/images/more-info.jpg" alt=''/>
                  </div>
                </div>
                <div className="col-md-6 align-self-center">
                  <div className="right-content">
                    <span>Who we are</span>
                    <h2>Get to know about <em>our company</em></h2>
                    <p>Welcome to COZFIN FINANCIAL ASSET SERVICES, where financial expertise meets personalized service. As the founder and a seasoned financial professional, I'm dedicated to helping individuals and families achieve their financial objectives.</p>
                    <a href="/about" className="filled-button">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="testimonials" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>What they say <em>about us</em></h2>
                <span>Testimonials from our valued clients on Mutual Funds and Future Goals</span>
              </div>
            </div>
            <div className="col-md-12">
              {/* React Slick Slider */}
              <Slider {...settings}>
                {/* Testimonial 1 */}
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Amit Kumar</p>
                    <span>Software Engineer</span>
                    <p>"I started investing in mutual funds a few years ago, and it's been one of the best decisions for my future. I now feel more secure about my financial goals."</p>
                  </div>
                </div>

                {/* Testimonial 2 */}
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Neha Gupta</p>
                    <span>Teacher</span>
                    <p>"Mutual funds have helped me achieve my long-term financial goals. I now have the confidence to plan for my children's education and my own retirement."</p>
                  </div>
                </div>

                {/* Testimonial 3 */}
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Ravi Singh</p>
                    <span>Business Owner</span>
                    <p>"After investing in mutual funds, I realized the importance of diversification. It has given me peace of mind, knowing that my future is financially secure."</p>
                  </div>
                </div>

                {/* Testimonial 4 */}
                <div className="testimonial-item">
                  <div className="inner-content">
                    <p className='testimonial-head'>Sonia Verma</p>
                    <span>Doctor</span>
                    <p>"As a doctor, I wanted to plan for my future and mutual funds have been a great way to build a retirement corpus. It's easy and reliable for long-term goals."</p>
                  </div>
                </div>

              </Slider>
            </div>
          </div>
        </div>
      </div>

    <div className="callback-form">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h2>Request a <em>call back</em></h2>
              <span>Feel free to send us a message now!</span>
            </div>
          </div>
          <div className="col-md-12">
            <div className="contact-form">
          
            <form id="contact" >
  <div className="row">
    <div className="col-lg-4 col-md-12 col-sm-12">
      <fieldset>
        <input name="name" type="text" className="form-control" id="name" placeholder="Full Name" required=""/>
      </fieldset>
    </div>
    <div className="col-lg-4 col-md-12 col-sm-12">
      <fieldset>
        <input name="email" type="email" className="form-control" id="email" placeholder="E-Mail Address" required=""/>
      </fieldset>
    </div>
    <div className="col-lg-4 col-md-12 col-sm-12">
      <fieldset>
        <input name="subject" type="text" className="form-control" id="subject" placeholder="Subject" required=""/>
      </fieldset>
    </div>
    <div className="col-lg-12">
      <fieldset>
        <textarea name="message" rows="6" className="form-control" id="message" placeholder="Your Message" required=""></textarea>
      </fieldset>
    </div>
    <div className="col-lg-12">
      <fieldset>
        <button type="submit" id="form-submit" className="border-button" onClick={handleSubmit}>Send Message</button>
      </fieldset>
    </div>
  </div>
</form>

            </div>
          </div>
        </div>
      </div>
    </div>

   

    </div>
  )
}

export default Home